import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import WorkDetail from "../../components/workdetail"
import mediamarktVideo from "../../assets/mediamarkt.mp4"

import ReactPlayer from "react-player"

import { playerWrapperSquare, reactPlayer } from "./mediamarkt.module.css"

const query = graphql`
  {
    allFile(filter: { name: { eq: "Mediamarkt" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const Mediamarkt = () => {
  const data = useStaticQuery(query)
  const mediamarktImage = data.allFile.nodes[0].childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Mediamarkt" />
      <WorkDetail
        company="MediaMarkt"
        image={mediamarktImage}
        description="A fictional concept and animation for the  end-of-year campaign of MediaMarkt."
        brief="As part of a project for the Belgian Advertising School, we got a fictional briefing by the agency Wunderman Thompson. We had to come up with a concept for the end-of-year campaign of MediaMarkt."
        solution="The name of our campaign was ‘MediaMarkt draait door’, with a ferris wheel as central theme. Customers could win prizes (TV’s, smartphones, fridges ...) if they could guess when the ferris wheel would stop spinning. I made a short animation video that makes the concept clear to the target audience. We also made a communication plan and an estimation of the total budget."
      />
      <div className={playerWrapperSquare}>
        <ReactPlayer
          url={mediamarktVideo}
          controls={true}
          width="100%"
          height="100%"
          pip={true}
          className={reactPlayer}
        />
      </div>
    </Layout>
  )
}

export default Mediamarkt
